import React, { useState, useEffect, useRef, createRef, Fragment } from 'react';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import clsx from 'clsx';
import get from 'lodash/get';
import { Hidden, GridList, Fade } from '@material-ui/core';
import { TESTCOMPONENT_CONTAINER } from './locators';
import Grid from '../../../components/molecules/Grid';
import Typography from '../../../components/atoms/Typography';
import CardMedia from '../../../components/atoms/CardMedia';
import Card from '../../../components/molecules/Card';
import CardContent from '../../../components/atoms/CardContent';
import Tabs from '../../../components/molecules/Tabs';
import Title from '../../../components/atoms/Title';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import { pushAnaylyticsData } from '../../../../utils/analytics';
import { analyticsData } from '../../../../constants/Analytics/analytics-data';
import Button from '../../../components/atoms/Button';
import useStyles from './style';
import {
  getPageName,
  getComponentName,
} from '../../../utils/getPageComponentInfo';
import { createDynamicLabels } from '../../../../utils/analytics';
import TextRotateAnimation from '../../../components/atoms/TextRotateAnimation';
import { canUseDOM } from '../../../utils/canUseDOM';
import { HOME_PAGE_ROUTE_NAME } from '../../../../constants';
import checkEmptyObject from '../../../utils/checkEmptyObject';

const CollageComponent = props => {
  // props.fields && props.fields.collageTabs && props.fields.collageTabs.forEach(item => {
  //   item.collages && item.collages.forEach(collage => {
  //     collage.fields.videoDesktop = {
  //       value: {
  //         src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
  //       },
  //     };
  //     collage.fields.videoMobile = {
  //       value: {
  //         src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
  //       },
  //     };
  //   });
  // });

  const classes = useStyles();

  const titleRef = useRef(null)
  const twcRef = useRef(null)
  const isFieldsEmpty = checkEmptyObject(props.fields);

  useEffect(() => {
    if (canUseDOM) {
      window.addEventListener('resize', handleResize)

      return _ => {
        window.removeEventListener('resize', handleResize)
      }
    }
  })

  useEffect(() => {
    handleResize()
  }, [])

  // useEffect(() => {
  //   if (twcRef.current) {
  //     twcRef.current.querySelector('.MuiTabs-flexContainer button:nth-child(2)').click()
  //     twcRef.current.querySelector('.MuiTabs-flexContainer button:nth-child(1)').click()
  //   }
  //   setTimeout(() => {
  //     if (twcRef.current && twcRef.current.querySelector('video').paused) {
  //       twcRef.current.querySelector('.MuiTabs-flexContainer button:nth-child(2)').click()
  //       twcRef.current.querySelector('.MuiTabs-flexContainer button:nth-child(1)').click()
  //     }
  //   }, 1000)
  // }, [twcRef.current])

  const handleResize = () => {
    if (titleRef.current) {
      setTimeout(() => {
        const currentRef = titleRef.current.ref;
        if (currentRef && currentRef.current) {
          document.documentElement.style.setProperty('--collageTitleTextHeight', `${currentRef.current.clientHeight}px`);
        }
      }, 100)
    }
  }

  const { segmentText, collageTabs, cta, title, SEOEnglishCTA } = props.fields;
  const params = props.params;
  const hasDualImage = params && get(params, 'Component Type', '') === 'DualImage'

  const pageName = getPageName(props);
  const isHomePage =
    pageName && pageName.toLowerCase() === HOME_PAGE_ROUTE_NAME.toLowerCase();

  const [headingComponent, setHeadingComponent] = useState('h1')

  useEffect(() => {

    if (!isHomePage) {
      setHeadingComponent('h2')
    } else {
      const elementCount = document.querySelectorAll(`div[data-locator=${TESTCOMPONENT_CONTAINER}]`).length;
      if (elementCount > 1) {
        setHeadingComponent('h2')
      }
    }

  }, [])

  const analyticsEventCTA = () => {
    const seoCtaText = get(SEOEnglishCTA, 'value', '');
    const obj = {
      ...analyticsData.pageBodyInteraction.arrowClick,
      ...{
        label: seoCtaText,
      },
    };
    pushAnaylyticsData(obj);
  };

  const addTileAnalyticsInfo = imgTitle => {
    const obj = {
      ...get(analyticsData, 'pageBodyInteraction.tileClick'),
      ...{
        label: createDynamicLabels([
          pageName,
          getComponentName(props),
          `${title && title.value}-${imgTitle && imgTitle.value}`,
        ]),
      },
    };
    pushAnaylyticsData(obj);
  };
  const onTabChange = index => {
    const obj = {
      ...get(analyticsData, 'pageBodyInteraction.miniNavClick'),
      ...{
        label: createDynamicLabels([
          pageName,
          getComponentName(props),
          `${title && title.value}-${get(
            collageTabs[index],
            'tabText.value',
            ''
          )}`,
        ]),
      },
    };
    pushAnaylyticsData(obj);
  };
  const renderCard = (tile, index = 0, isMobile) => {
    const image =
      (get(tile, 'image.value.src', '') && tile.image) ||
      get(tile, 'gallery[0].fields.image', '');
    const mobileImage =
      (get(tile, 'mobileImage.value.src', '') && tile.mobileImage) ||
      get(tile, 'gallery[0].fields.mobileImage', '');
    const videoDesktop =
      (get(tile, 'video.value.href', '') && tile.video) ||
      get(tile, 'gallery[0].fields.video', '');
    const videoMobile =
      (get(tile, 'videoMobile.value.href', '') && tile.videoMobile) ||
      get(tile, 'gallery[0].fields.videoMobile', '');
    const link =
      (get(tile, 'collageLink.value.href', '') && tile.collageLink) ||
      (tile && { value: { href: tile.link } }); // This is a case where if link object is not present then direct link href value will be provided
    const title = tile && (tile.title || tile.name);
    const location = tile && tile.location;

    const hasVideo = videoDesktop && videoDesktop.value && (videoDesktop.value.src || videoDesktop.value.url || videoDesktop.value.href);

    const getCardTimeoutDuration = () => {
      setTimeout(() => { }, 300); // This will prevent fading start to all the cards at simultaneously
      if (index === 0) {
        return (index + 1) * 500;
      }
      return index * 700;
    };

    return (
      <Fade in={true} key={index} timeout={getCardTimeoutDuration()}>
        <Card
          className={clsx(
            classes.root,
            classes.cardWrapper,
            classes[`card-${index}`]
          )}
          key={index}
          square
          link={(isMobile && hasVideo) ? false : link}
          onClick={() => { if (isMobile && hasVideo) { return addTileAnalyticsInfo(title) } else { return undefined } }}
        >
          <CardMedia
            className="card-media"
            image={image}
            mobileImage={mobileImage}
            videoDesktop={videoDesktop}
            videoMobile={videoMobile}
            aspectRatio={{ mobile: '2x1', desktop: '2x1' }}
            alternateAltText={title && title.value}
          />
          <ConditionalWrapper
            condition={(isMobile && hasVideo)}
            wrapper={children => (
              <a
                href={link.value.href}
                className="bot-link"
                onClick={() => { if (isMobile) { return addTileAnalyticsInfo(title) } else { return undefined } }}>
                {children}
              </a>
            )}
          >
            <CardContent className={clsx(classes.content, 'card-content')}>
              {location && (
                <Typography variant="body2" color="textSecondary" component="p">
                  <Text field={location} />
                </Typography>
              )}
              {title && (
                <Typography
                  variant="h5"
                  className="card-title"
                  component="h3"
                  wordBreak
                >
                  <Text field={title} />
                </Typography>
              )}
            </CardContent>
          </ConditionalWrapper>
        </Card>
      </Fade>
    );
  };

  const renderTwoCards = (tiles, isMobile) => {
    const getCardTimeoutDuration = (index) => {
      return index === 0 ? 500 : 1200;
    };

    return (
      <>
        {tiles && tiles.length > 0 && tiles.map((tile, index) => {
          const image =
            (get(tile, 'fields.image.value.src', '') && tile.fields.image);
          const mobileImage =
            (get(tile, 'fields.mobileImage.value.src', '') && tile.fields.mobileImage);
          const videoDesktop =
            (get(tile, 'fields.video.value.src', get(tile, 'fields.video.value.href', '')) && tile.fields.video);
          const videoMobile =
            (get(tile, 'fields.videoMobile.value.src', get(tile, 'fields.videoMobile.value.href', '')) && tile.fields.videoMobile);
          const link =
            (get(tile, 'fields.collageLink.value.href', '') && tile.fields.collageLink) ||
            (tile && { value: { href: tile.fields.link } }); // This is a case where if link object is not present then direct link href value will be provided

          const title = tile && (tile.fields.title || tile.fields.name);
          const location = tile && tile.fields.location;

          const hasVideo = videoDesktop && videoDesktop.value && (videoDesktop.value.src || videoDesktop.value.url || videoDesktop.value.href);

          const aspectRatioKey = index === 0 ? '3x2' : '3x3'

          return (
            <ConditionalWrapper
              key={index}
              condition={isMobile}
              wrapper={children => (
                <li className="item" key={index}>
                  {children}
                </li>
              )}
            >
              <Grid item md={isMobile ? 12 : index === 0 ? 5 : 6} className={index === 0 ? classes['col-left'] : classes['col-right']}>
                <Fade in={true} key={index} timeout={getCardTimeoutDuration(index)}>
                  <Card
                    className={clsx(
                      classes.root,
                      classes.cardWrapperV2,
                      index === 0 ? classes['card-left'] : classes['card-right']
                    )}
                    key={index}
                    square
                    link={(isMobile && hasVideo) ? false : link}
                    onClick={() => { if (isMobile && hasVideo) { return addTileAnalyticsInfo(title) } else { return undefined } }}
                  >
                    <CardMedia
                      className="card-media"
                      image={image}
                      mobileImage={mobileImage}
                      videoDesktop={videoDesktop}
                      videoMobile={videoMobile}
                      aspectRatio={{ mobile: '2x1', desktop: aspectRatioKey }}
                      // preferredSize={!isMobile ? aspectRatioKey : '2x1'}
                      alternateAltText={title && title.value}
                    />
                    <ConditionalWrapper
                      condition={(isMobile && hasVideo)}
                      wrapper={children => (
                        <a
                          href={link.value.href}
                          className="bot-link"
                          onClick={() => { if (isMobile) { return addTileAnalyticsInfo(title) } else { return undefined } }}>
                          {children}
                        </a>
                      )}
                    >
                      <CardContent className={clsx(classes.content, 'card-content')}>
                        {location && (
                          <Typography variant="body2" color="textSecondary" component="p">
                            <Text field={location} />
                          </Typography>
                        )}
                        {title && (
                          <Typography
                            variant="h5"
                            className="card-title"
                            component="h3"
                            wordBreak
                          >
                            <Text field={title} />
                          </Typography>
                        )}
                      </CardContent>
                    </ConditionalWrapper>
                  </Card>
                </Fade>
              </Grid>
            </ConditionalWrapper>
          )
        })}
      </>
    );
  };

  const tabData = collages => {
    if (!collages || collages.length < 1) return null;
    const [firstCollage, ...restCollages] = collages;

    return (
      <Fragment>
        <Grid container classes={{ root: 'tabs-desktop-view' }}>
          <Grid item md={8} className={`${classes.row1} row1`}>
            {renderCard(firstCollage.fields, 2, false)}
          </Grid>
          <Grid item md={4} className={`${classes.row2} row2`}>
            {restCollages &&
              restCollages.map((collage, index) =>
                renderCard(collage.fields, index + 1, false)
              )}
          </Grid>
        </Grid>
        <Hidden lgUp implementation="css">
          <GridList
            className={classes.gridList}
            cols={1.3}
            cellHeight="auto"
            spacing={32}
          >
            {collages &&
              collages.map((collage, index) => (
                <li className="item" key={index}>
                  {renderCard(collage.fields, index, true)}
                </li>
              ))}
          </GridList>
          {get(cta, 'value.text') && (
            <Button
              hasBorder={false}
              field={cta}
              className={classes.exploreButton}
              size="small"
              onClick={analyticsEventCTA}
            />
          )}
        </Hidden>
      </Fragment>
    );
  };

  const tabDataFor2Images = collages => {
    if (!collages || collages.length < 1) return null;
    const firstTwoCollage = collages.slice(0, 2);

    return (
      <Fragment>
        <Grid container classes={{ root: `tabs-desktop-view ${classes.cardsContainer}` }}>
          {renderTwoCards(firstTwoCollage, false)}
        </Grid>
        <Hidden lgUp implementation="css">
          <GridList
            className={classes.gridList}
            cols={1.3}
            cellHeight="auto"
            spacing={32}
          >
            {renderTwoCards(firstTwoCollage, true)}
          </GridList>
          {get(cta, 'value.text') && (
            <Button
              hasBorder={false}
              field={cta}
              className={classes.exploreButton}
              size="small"
            />
          )}
        </Hidden>
      </Fragment>
    );
  };

  const createTabs =
    collageTabs &&
    collageTabs.map(collageTab => {
      const { collages, tabText } = collageTab || '';

      return {
        data: hasDualImage ? tabDataFor2Images(collages) : tabData(collages),
        label: tabText && tabText.value,
      };
    });

  return (
    isFieldsEmpty ? <></> :
      <div className={classes.wrapper} data-locator={TESTCOMPONENT_CONTAINER} ref={twcRef}>
        <Grid>
          {!hasDualImage && segmentText && (
            <TextRotateAnimation
              condition={true}
              animateSettings={{
                willAnimate: true,
                selfTrigger: true,
                duration: '250ms',
              }}
            >
              <Title text={segmentText} />
            </TextRotateAnimation>
          )}
          {title && (
            <Typography
              variant="h2"
              component={headingComponent}
              gutterBottom={false}
              className={clsx(() => { return (hasDualImage ? classes.headingV2 : classes.heading) }, 'tabs-with-collage-heading')}
              ref={titleRef}
              wordBreak
              animateSettings={{
                willAnimate: true,
                selfTrigger: true,
              }}
            >
              <Text field={title} />
            </Typography>
          )}
          <Tabs
            tabs={createTabs}
            className="pills-tabs-container"
            onTabChange={index => onTabChange(index)}
          />
        </Grid>
      </div>
  );
};

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(CollageComponent))
);
